import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TextRecommendationFragmentDoc } from '../fragments/TextRecommendation.fragment';
export type CreateRecommendationMutationVariables = Types.Exact<{
	input: Types.CreateRecommendationInput;
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	platform?: Types.InputMaybe<Types.Platform>;
	excludeTextRecommendationTitle?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type CreateRecommendationMutation = {
	__typename?: 'Mutation';
	createRecommendation: {
		__typename?: 'CreateRecommendationPayload';
		textRecommendation: {
			__typename: 'TextRecommendation';
			id: string;
			headline?: string | null;
			body: string;
			originalHeadline?: string | null;
			originalBody?: string | null;
			customProfileType?: string | null;
			watchedAt: any;
			likeCount: number;
			likedByUser: boolean;
			ownedByUser: boolean;
			updatedAt: any;
			tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
			watchedOn?: {
				__typename?: 'Package';
				clearName: string;
				id: string;
				shortName: string;
				technicalName: string;
				packageId: number;
				selected: boolean;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				addonParent?: { __typename?: 'Package'; id: string } | null;
			} | null;
			profile: {
				__typename: 'Profile';
				displayName: string;
				firstName: string;
				lastName: string;
				location?: string | null;
				country: any;
				bio?: string | null;
				avatarUrl?: string | null;
				isComplete: boolean;
				ownedByUser: boolean;
				profileUrl: string;
				profileType: Types.ProfileType;
				id: string;
				externalUrls?: Array<{
					__typename?: 'ProfileSpecificLink';
					type: Types.ProfileSpecificLinkType;
					name?: string | null;
					url: string;
				}> | null;
			};
			title?:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'SeasonContent';
							seasonNumber: number;
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						show: {
							__typename: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: { __typename?: 'ShowContent'; title: string; fullPath: string };
							watchNowOffer?: {
								__typename: 'Offer';
								id: string;
								standardWebURL?: string | null;
								preAffiliatedStandardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								dateCreated?: any | null;
								newElementCount?: number | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							} | null;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; progress: number };
							tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  };
		};
	};
};

export const CreateRecommendationDocument = gql`
	mutation CreateRecommendation(
		$input: CreateRecommendationInput!
		$country: Country!
		$language: Language!
		$platform: Platform = WEB
		$excludeTextRecommendationTitle: Boolean = true
	) {
		createRecommendation(input: $input) {
			textRecommendation {
				...TextRecommendation
			}
		}
	}
	${TextRecommendationFragmentDoc}
`;
