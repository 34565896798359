import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from './WatchNowOffer.fragment';
import {
	TitleOfferFragmentDoc,
	FastOfferFragmentDoc,
} from '../../../components/buybox/graphql/fragments/Offer.fragment';
import { TrailerClipsFragmentDoc } from './TrailerClips.fragment';
import { TextRecommendationFragmentDoc } from '../../../features/why-to-watch/graphql/fragments/TextRecommendation.fragment';
import { SponsoredAdFragmentDoc } from './SponsoredAd.fragment';
import { EpisodeFragmentDoc } from './Episode.fragment';
export type TitleDetails_Movie_Fragment = {
	__typename: 'Movie';
	permanentAudiences: Array<string>;
	id: string;
	objectType: Types.ObjectType;
	objectId: number;
	recommendedByCount: number;
	watchedByCount: number;
	disneyOffersCount: number;
	starOffersCount: number;
	uniqueOfferCount: number;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{
		__typename?: 'TitleListEntry';
		createdAt: any;
		genericTitleList: { __typename?: 'GenericTitleList'; id: string };
	}>;
	similarTitlesV2: {
		__typename?: 'SimilarTitlesConnection';
		sponsoredAd?: {
			__typename?: 'SponsoredRecommendationAd';
			bidId: string;
			holdoutGroup: boolean;
			campaign?: {
				__typename?: 'SponsoredRecommendationCampaign';
				name: string;
				countdownTimer?: any | null;
				creativeType?: Types.CreativeType | null;
				disclaimerText?: string | null;
				hideDetailPageButton: boolean;
				hideImdbScore?: boolean | null;
				hideJwScore?: boolean | null;
				hideRatings: boolean;
				hideContent?: boolean | null;
				posterOverride?: string | null;
				promotionalImageUrl?: string | null;
				promotionalTitle?: string | null;
				promotionalText?: string | null;
				promotionalProviderLogo?: string | null;
				promotionalProviderWideLogo?: string | null;
				watchNowLabel?: string | null;
				backgroundImages?: Array<{
					__typename?: 'BackgroundImage';
					imageURL: string;
					size: Types.BackgroundImageSize;
				} | null> | null;
				externalTrackers: Array<{
					__typename?: 'ExternalTracker';
					type: Types.ExternalTrackerType;
					data: string;
				}>;
				promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
				watchNowOffer: {
					__typename: 'Offer';
					id: string;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					dateCreated?: any | null;
					newElementCount?: number | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				};
				nodeOverrides: Array<{
					__typename?: 'NodeOverride';
					nodeId: string;
					promotionalImageUrl?: string | null;
					watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
				}>;
				node:
					| { __typename: 'Article'; nodeId: string }
					| { __typename: 'Author'; nodeId: string }
					| { __typename: 'Bundle'; nodeId: string }
					| { __typename: 'Device'; nodeId: string }
					| { __typename: 'Episode'; nodeId: string }
					| {
							__typename: 'GenericTitleList';
							id: string;
							type: Types.GenericTitleListType;
							nodeId: string;
							followedlistEntry?: {
								__typename?: 'FollowedListEntry';
								createdAt: any;
								name: string;
							} | null;
							content: {
								__typename?: 'GenericTitleListContent';
								name: string;
								visibility: Types.GenericTitleListVisibility;
							};
							titles: {
								__typename?: 'GenericTitleListConnection';
								totalCount: number;
								edges?: Array<{
									__typename?: 'GenericTitleListEdge';
									cursor: string;
									node:
										| {
												__typename?: 'Movie';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'MovieContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Season';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'SeasonContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Show';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'ShowContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  };
								}> | null;
							};
					  }
					| { __typename: 'Genre'; nodeId: string }
					| {
							__typename: 'Movie';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'MovieContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					  }
					| { __typename: 'MultiStepSportEvent'; nodeId: string }
					| { __typename: 'Offer'; nodeId: string }
					| { __typename: 'Package'; nodeId: string }
					| { __typename: 'Page'; nodeId: string }
					| { __typename: 'Person'; nodeId: string }
					| {
							__typename: 'Season';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'SeasonContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								seasonNumber: number;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							show: {
								__typename: 'Show';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: { __typename?: 'ShowContent'; originalTitle: string };
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							};
					  }
					| {
							__typename: 'Show';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'ShowContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
					  }
					| { __typename: 'SingleStepSportEvent'; nodeId: string }
					| { __typename: 'SportCompetitionV2'; nodeId: string }
					| { __typename: 'SportCompetitorV2'; nodeId: string }
					| { __typename: 'Url'; nodeId: string };
			} | null;
		} | null;
	};
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	plexPlayerOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	justwatchTVOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	offers: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{
		__typename?: 'AvailableTo';
		availableCountDown: number;
		availableToDate: any;
		package: { __typename?: 'Package'; id: string; shortName: string };
	}>;
	fallBackClips: {
		__typename?: 'MovieContent';
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
	};
	content: {
		__typename?: 'MovieContent';
		originalTitle: string;
		ageCertification?: string | null;
		productionCountries: Array<any>;
		fullPath: string;
		posterUrl?: string | null;
		runtime?: number | null;
		isReleased: boolean;
		shortDescription?: string | null;
		title: string;
		originalReleaseYear?: number | null;
		originalReleaseDate?: any | null;
		fullPosterUrl?: string | null;
		subgenres: Array<{
			__typename?: 'GenericTitleList';
			content: {
				__typename?: 'GenericTitleListContent';
				shortName?: string | null;
				name: string;
				url?: { __typename?: 'Url'; fullPath: string } | null;
			};
		}>;
		credits: Array<{
			__typename?: 'Credit';
			role: Types.CreditRole;
			name: string;
			characterName?: string | null;
			personId: number;
		}>;
		interactions: {
			__typename?: 'InteractionAttributes';
			dislikelistAdditions: number;
			likelistAdditions: number;
			votesNumber: number;
		};
		tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null; wikidataId?: string | null };
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseCountDown?: number | null;
			releaseDate?: any | null;
			releaseType: Types.ReleaseType;
			label: Types.UpcomingReleaseLabel;
			package?: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				shortName: string;
				clearName: string;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				iconWide: string;
				hasRectangularIcon: boolean;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					retailPrice?: string | null;
					durationDays?: number | null;
					presentationType?: Types.PresentationType | null;
					isTrial?: boolean | null;
					retailPriceValue?: number | null;
					currency?: string | null;
				}>;
			} | null;
		}>;
		genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
		textRecommendations?: Array<{
			__typename: 'TextRecommendation';
			id: string;
			headline?: string | null;
			body: string;
			originalHeadline?: string | null;
			originalBody?: string | null;
			customProfileType?: string | null;
			watchedAt: any;
			likeCount: number;
			likedByUser: boolean;
			ownedByUser: boolean;
			updatedAt: any;
			tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
			watchedOn?: {
				__typename?: 'Package';
				clearName: string;
				id: string;
				shortName: string;
				technicalName: string;
				packageId: number;
				selected: boolean;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				addonParent?: { __typename?: 'Package'; id: string } | null;
			} | null;
			profile: {
				__typename: 'Profile';
				displayName: string;
				firstName: string;
				lastName: string;
				location?: string | null;
				country: any;
				bio?: string | null;
				avatarUrl?: string | null;
				isComplete: boolean;
				ownedByUser: boolean;
				profileUrl: string;
				profileType: Types.ProfileType;
				id: string;
				externalUrls?: Array<{
					__typename?: 'ProfileSpecificLink';
					type: Types.ProfileSpecificLinkType;
					name?: string | null;
					url: string;
				}> | null;
			};
			title?:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'SeasonContent';
							seasonNumber: number;
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						show: {
							__typename: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: { __typename?: 'ShowContent'; title: string; fullPath: string };
							watchNowOffer?: {
								__typename: 'Offer';
								id: string;
								standardWebURL?: string | null;
								preAffiliatedStandardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								dateCreated?: any | null;
								newElementCount?: number | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							} | null;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; progress: number };
							tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  };
		}> | null;
	};
	popularityRank: {
		__typename?: 'PopularityRank';
		rank: number;
		trend: Types.ModuleContentTitleTrend;
		trendDifference: number;
	};
	streamingCharts: {
		__typename?: 'StreamingChartsConnection';
		edges?: Array<{
			__typename?: 'StreamingChartsTitlesEdge';
			streamingChartInfo?: {
				__typename?: 'StreamingChartInfo';
				rank: number;
				trend: Types.ModuleContentTitleTrend;
				trendDifference: number;
				updatedAt?: any | null;
				daysInTop10: number;
				daysInTop100: number;
				daysInTop1000: number;
				daysInTop3: number;
				topRank: number;
			} | null;
		}> | null;
	};
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleDetails_Season_Fragment = {
	__typename: 'Season';
	totalEpisodeCount: number;
	id: string;
	objectType: Types.ObjectType;
	objectId: number;
	recommendedByCount: number;
	watchedByCount: number;
	disneyOffersCount: number;
	starOffersCount: number;
	uniqueOfferCount: number;
	episodes: Array<{
		__typename?: 'Episode';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		uniqueOfferCount: number;
		seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		flatrate: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		buy: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		rent: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		free: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		fast: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		content: {
			__typename?: 'EpisodeContent';
			title: string;
			shortDescription?: string | null;
			episodeNumber: number;
			seasonNumber: number;
			isReleased: boolean;
			runtime?: number | null;
			upcomingReleases: Array<{
				__typename?: 'UpcomingRelease';
				releaseDate?: any | null;
				label: Types.UpcomingReleaseLabel;
				package?: { __typename?: 'Package'; id: string; packageId: number } | null;
			}>;
		};
	}>;
	show: {
		__typename: 'Show';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		totalSeasonCount: number;
		customlistEntries: Array<{
			__typename?: 'TitleListEntry';
			createdAt: any;
			genericTitleList: { __typename?: 'GenericTitleList'; id: string };
		}>;
		tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		fallBackClips: {
			__typename?: 'ShowContent';
			clips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
			videobusterClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
			dailymotionClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
		};
		content: {
			__typename?: 'ShowContent';
			title: string;
			ageCertification?: string | null;
			fullPath: string;
			productionCountries: Array<any>;
			posterUrl?: string | null;
			isReleased: boolean;
			genres: Array<{ __typename?: 'Genre'; shortName: string }>;
			credits: Array<{
				__typename?: 'Credit';
				role: Types.CreditRole;
				name: string;
				characterName?: string | null;
				personId: number;
			}>;
			externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
			upcomingReleases: Array<{
				__typename?: 'UpcomingRelease';
				releaseDate?: any | null;
				releaseType: Types.ReleaseType;
				package?: {
					__typename?: 'Package';
					id: string;
					shortName: string;
					planOffers: Array<{
						__typename?: 'PackagePlanOffer';
						retailPrice?: string | null;
						durationDays?: number | null;
						presentationType?: Types.PresentationType | null;
						isTrial?: boolean | null;
						retailPriceValue?: number | null;
						currency?: string | null;
					}>;
				} | null;
			}>;
			backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
			videobusterClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
			dailymotionClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
		};
		seenState: { __typename?: 'ShowSeenState'; progress: number };
		watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		similarTitlesV2: {
			__typename?: 'SimilarTitlesConnection';
			sponsoredAd?: {
				__typename?: 'SponsoredRecommendationAd';
				bidId: string;
				holdoutGroup: boolean;
				campaign?: {
					__typename?: 'SponsoredRecommendationCampaign';
					name: string;
					countdownTimer?: any | null;
					creativeType?: Types.CreativeType | null;
					disclaimerText?: string | null;
					hideDetailPageButton: boolean;
					hideImdbScore?: boolean | null;
					hideJwScore?: boolean | null;
					hideRatings: boolean;
					hideContent?: boolean | null;
					posterOverride?: string | null;
					promotionalImageUrl?: string | null;
					promotionalTitle?: string | null;
					promotionalText?: string | null;
					promotionalProviderLogo?: string | null;
					promotionalProviderWideLogo?: string | null;
					watchNowLabel?: string | null;
					backgroundImages?: Array<{
						__typename?: 'BackgroundImage';
						imageURL: string;
						size: Types.BackgroundImageSize;
					} | null> | null;
					externalTrackers: Array<{
						__typename?: 'ExternalTracker';
						type: Types.ExternalTrackerType;
						data: string;
					}>;
					promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
					watchNowOffer: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					};
					nodeOverrides: Array<{
						__typename?: 'NodeOverride';
						nodeId: string;
						promotionalImageUrl?: string | null;
						watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
					}>;
					node:
						| { __typename: 'Article'; nodeId: string }
						| { __typename: 'Author'; nodeId: string }
						| { __typename: 'Bundle'; nodeId: string }
						| { __typename: 'Device'; nodeId: string }
						| { __typename: 'Episode'; nodeId: string }
						| {
								__typename: 'GenericTitleList';
								id: string;
								type: Types.GenericTitleListType;
								nodeId: string;
								followedlistEntry?: {
									__typename?: 'FollowedListEntry';
									createdAt: any;
									name: string;
								} | null;
								content: {
									__typename?: 'GenericTitleListContent';
									name: string;
									visibility: Types.GenericTitleListVisibility;
								};
								titles: {
									__typename?: 'GenericTitleListConnection';
									totalCount: number;
									edges?: Array<{
										__typename?: 'GenericTitleListEdge';
										cursor: string;
										node:
											| {
													__typename?: 'Movie';
													id: string;
													objectId: number;
													objectType: Types.ObjectType;
													content: {
														__typename?: 'MovieContent';
														fullPath: string;
														posterUrl?: string | null;
														title: string;
														originalReleaseYear?: number | null;
														isReleased: boolean;
														scoring: {
															__typename?: 'Scoring';
															imdbVotes?: number | null;
															imdbScore?: number | null;
															tomatoMeter?: number | null;
															certifiedFresh?: boolean | null;
															jwRating?: number | null;
														};
													};
											  }
											| {
													__typename?: 'Season';
													id: string;
													objectId: number;
													objectType: Types.ObjectType;
													content: {
														__typename?: 'SeasonContent';
														fullPath: string;
														posterUrl?: string | null;
														title: string;
														originalReleaseYear?: number | null;
														isReleased: boolean;
														scoring: {
															__typename?: 'Scoring';
															imdbVotes?: number | null;
															imdbScore?: number | null;
															tomatoMeter?: number | null;
															certifiedFresh?: boolean | null;
															jwRating?: number | null;
														};
													};
											  }
											| {
													__typename?: 'Show';
													id: string;
													objectId: number;
													objectType: Types.ObjectType;
													content: {
														__typename?: 'ShowContent';
														fullPath: string;
														posterUrl?: string | null;
														title: string;
														originalReleaseYear?: number | null;
														isReleased: boolean;
														scoring: {
															__typename?: 'Scoring';
															imdbVotes?: number | null;
															imdbScore?: number | null;
															tomatoMeter?: number | null;
															certifiedFresh?: boolean | null;
															jwRating?: number | null;
														};
													};
											  };
									}> | null;
								};
						  }
						| { __typename: 'Genre'; nodeId: string }
						| {
								__typename: 'Movie';
								objectId: number;
								objectType: Types.ObjectType;
								nodeId: string;
								content: {
									__typename?: 'MovieContent';
									fullPath: string;
									posterUrl?: string | null;
									title: string;
									originalReleaseYear?: number | null;
									isReleased: boolean;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										jwRating?: number | null;
									};
									genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
									externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
								offers: Array<{
									__typename?: 'Offer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									id: string;
									package: { __typename?: 'Package'; id: string; packageId: number };
								}>;
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						  }
						| { __typename: 'MultiStepSportEvent'; nodeId: string }
						| { __typename: 'Offer'; nodeId: string }
						| { __typename: 'Package'; nodeId: string }
						| { __typename: 'Page'; nodeId: string }
						| { __typename: 'Person'; nodeId: string }
						| {
								__typename: 'Season';
								objectId: number;
								objectType: Types.ObjectType;
								nodeId: string;
								content: {
									__typename?: 'SeasonContent';
									fullPath: string;
									posterUrl?: string | null;
									title: string;
									originalReleaseYear?: number | null;
									isReleased: boolean;
									seasonNumber: number;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										jwRating?: number | null;
									};
									genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
									externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
								offers: Array<{
									__typename?: 'Offer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									id: string;
									package: { __typename?: 'Package'; id: string; packageId: number };
								}>;
								show: {
									__typename: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									content: { __typename?: 'ShowContent'; originalTitle: string };
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								};
						  }
						| {
								__typename: 'Show';
								objectId: number;
								objectType: Types.ObjectType;
								nodeId: string;
								content: {
									__typename?: 'ShowContent';
									fullPath: string;
									posterUrl?: string | null;
									title: string;
									originalReleaseYear?: number | null;
									isReleased: boolean;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										jwRating?: number | null;
									};
									genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
									externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
								offers: Array<{
									__typename?: 'Offer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									id: string;
									package: { __typename?: 'Package'; id: string; packageId: number };
								}>;
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
						  }
						| { __typename: 'SingleStepSportEvent'; nodeId: string }
						| { __typename: 'SportCompetitionV2'; nodeId: string }
						| { __typename: 'SportCompetitorV2'; nodeId: string }
						| { __typename: 'Url'; nodeId: string };
				} | null;
			} | null;
		};
	};
	seenState: { __typename?: 'ShowSeenState'; progress: number };
	plexPlayerOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	justwatchTVOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	offers: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{
		__typename?: 'AvailableTo';
		availableCountDown: number;
		availableToDate: any;
		package: { __typename?: 'Package'; id: string; shortName: string };
	}>;
	fallBackClips: {
		__typename?: 'SeasonContent';
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
	};
	content: {
		__typename?: 'SeasonContent';
		seasonNumber: number;
		fullPath: string;
		posterUrl?: string | null;
		runtime?: number | null;
		isReleased: boolean;
		shortDescription?: string | null;
		title: string;
		originalReleaseYear?: number | null;
		originalReleaseDate?: any | null;
		fullPosterUrl?: string | null;
		subgenres: Array<{
			__typename?: 'GenericTitleList';
			content: {
				__typename?: 'GenericTitleListContent';
				shortName?: string | null;
				name: string;
				url?: { __typename?: 'Url'; fullPath: string } | null;
			};
		}>;
		interactions: {
			__typename?: 'InteractionAttributes';
			dislikelistAdditions: number;
			likelistAdditions: number;
			votesNumber: number;
		};
		tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null; wikidataId?: string | null };
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseCountDown?: number | null;
			releaseDate?: any | null;
			releaseType: Types.ReleaseType;
			label: Types.UpcomingReleaseLabel;
			package?: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				shortName: string;
				clearName: string;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				iconWide: string;
				hasRectangularIcon: boolean;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					retailPrice?: string | null;
					durationDays?: number | null;
					presentationType?: Types.PresentationType | null;
					isTrial?: boolean | null;
					retailPriceValue?: number | null;
					currency?: string | null;
				}>;
			} | null;
		}>;
		genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
		textRecommendations?: Array<{
			__typename: 'TextRecommendation';
			id: string;
			headline?: string | null;
			body: string;
			originalHeadline?: string | null;
			originalBody?: string | null;
			customProfileType?: string | null;
			watchedAt: any;
			likeCount: number;
			likedByUser: boolean;
			ownedByUser: boolean;
			updatedAt: any;
			tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
			watchedOn?: {
				__typename?: 'Package';
				clearName: string;
				id: string;
				shortName: string;
				technicalName: string;
				packageId: number;
				selected: boolean;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				addonParent?: { __typename?: 'Package'; id: string } | null;
			} | null;
			profile: {
				__typename: 'Profile';
				displayName: string;
				firstName: string;
				lastName: string;
				location?: string | null;
				country: any;
				bio?: string | null;
				avatarUrl?: string | null;
				isComplete: boolean;
				ownedByUser: boolean;
				profileUrl: string;
				profileType: Types.ProfileType;
				id: string;
				externalUrls?: Array<{
					__typename?: 'ProfileSpecificLink';
					type: Types.ProfileSpecificLinkType;
					name?: string | null;
					url: string;
				}> | null;
			};
			title?:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'SeasonContent';
							seasonNumber: number;
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						show: {
							__typename: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: { __typename?: 'ShowContent'; title: string; fullPath: string };
							watchNowOffer?: {
								__typename: 'Offer';
								id: string;
								standardWebURL?: string | null;
								preAffiliatedStandardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								dateCreated?: any | null;
								newElementCount?: number | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							} | null;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; progress: number };
							tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  };
		}> | null;
	};
	popularityRank: {
		__typename?: 'PopularityRank';
		rank: number;
		trend: Types.ModuleContentTitleTrend;
		trendDifference: number;
	};
	streamingCharts: {
		__typename?: 'StreamingChartsConnection';
		edges?: Array<{
			__typename?: 'StreamingChartsTitlesEdge';
			streamingChartInfo?: {
				__typename?: 'StreamingChartInfo';
				rank: number;
				trend: Types.ModuleContentTitleTrend;
				trendDifference: number;
				updatedAt?: any | null;
				daysInTop10: number;
				daysInTop100: number;
				daysInTop1000: number;
				daysInTop3: number;
				topRank: number;
			} | null;
		}> | null;
	};
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleDetails_Show_Fragment = {
	__typename: 'Show';
	permanentAudiences: Array<string>;
	totalSeasonCount: number;
	id: string;
	objectType: Types.ObjectType;
	objectId: number;
	recommendedByCount: number;
	watchedByCount: number;
	disneyOffersCount: number;
	starOffersCount: number;
	uniqueOfferCount: number;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{
		__typename?: 'TitleListEntry';
		createdAt: any;
		genericTitleList: { __typename?: 'GenericTitleList'; id: string };
	}>;
	similarTitlesV2: {
		__typename?: 'SimilarTitlesConnection';
		sponsoredAd?: {
			__typename?: 'SponsoredRecommendationAd';
			bidId: string;
			holdoutGroup: boolean;
			campaign?: {
				__typename?: 'SponsoredRecommendationCampaign';
				name: string;
				countdownTimer?: any | null;
				creativeType?: Types.CreativeType | null;
				disclaimerText?: string | null;
				hideDetailPageButton: boolean;
				hideImdbScore?: boolean | null;
				hideJwScore?: boolean | null;
				hideRatings: boolean;
				hideContent?: boolean | null;
				posterOverride?: string | null;
				promotionalImageUrl?: string | null;
				promotionalTitle?: string | null;
				promotionalText?: string | null;
				promotionalProviderLogo?: string | null;
				promotionalProviderWideLogo?: string | null;
				watchNowLabel?: string | null;
				backgroundImages?: Array<{
					__typename?: 'BackgroundImage';
					imageURL: string;
					size: Types.BackgroundImageSize;
				} | null> | null;
				externalTrackers: Array<{
					__typename?: 'ExternalTracker';
					type: Types.ExternalTrackerType;
					data: string;
				}>;
				promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
				watchNowOffer: {
					__typename: 'Offer';
					id: string;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					dateCreated?: any | null;
					newElementCount?: number | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				};
				nodeOverrides: Array<{
					__typename?: 'NodeOverride';
					nodeId: string;
					promotionalImageUrl?: string | null;
					watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
				}>;
				node:
					| { __typename: 'Article'; nodeId: string }
					| { __typename: 'Author'; nodeId: string }
					| { __typename: 'Bundle'; nodeId: string }
					| { __typename: 'Device'; nodeId: string }
					| { __typename: 'Episode'; nodeId: string }
					| {
							__typename: 'GenericTitleList';
							id: string;
							type: Types.GenericTitleListType;
							nodeId: string;
							followedlistEntry?: {
								__typename?: 'FollowedListEntry';
								createdAt: any;
								name: string;
							} | null;
							content: {
								__typename?: 'GenericTitleListContent';
								name: string;
								visibility: Types.GenericTitleListVisibility;
							};
							titles: {
								__typename?: 'GenericTitleListConnection';
								totalCount: number;
								edges?: Array<{
									__typename?: 'GenericTitleListEdge';
									cursor: string;
									node:
										| {
												__typename?: 'Movie';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'MovieContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Season';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'SeasonContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Show';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'ShowContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  };
								}> | null;
							};
					  }
					| { __typename: 'Genre'; nodeId: string }
					| {
							__typename: 'Movie';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'MovieContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					  }
					| { __typename: 'MultiStepSportEvent'; nodeId: string }
					| { __typename: 'Offer'; nodeId: string }
					| { __typename: 'Package'; nodeId: string }
					| { __typename: 'Page'; nodeId: string }
					| { __typename: 'Person'; nodeId: string }
					| {
							__typename: 'Season';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'SeasonContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								seasonNumber: number;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							show: {
								__typename: 'Show';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: { __typename?: 'ShowContent'; originalTitle: string };
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							};
					  }
					| {
							__typename: 'Show';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'ShowContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
					  }
					| { __typename: 'SingleStepSportEvent'; nodeId: string }
					| { __typename: 'SportCompetitionV2'; nodeId: string }
					| { __typename: 'SportCompetitorV2'; nodeId: string }
					| { __typename: 'Url'; nodeId: string };
			} | null;
		} | null;
	};
	seenState: { __typename?: 'ShowSeenState'; progress: number; seenEpisodeCount: number };
	tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	offers: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	seasons: Array<{
		__typename?: 'Season';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		totalEpisodeCount: number;
		availableTo: Array<{
			__typename?: 'AvailableTo';
			availableToDate: any;
			availableCountDown: number;
			package: { __typename?: 'Package'; id: string; shortName: string };
		}>;
		offers: Array<{
			__typename?: 'Offer';
			monetizationType: Types.MonetizationType;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			package: { __typename?: 'Package'; clearName: string; shortName: string };
		}>;
		content: {
			__typename?: 'SeasonContent';
			posterUrl?: string | null;
			seasonNumber: number;
			fullPath: string;
			title: string;
			isReleased: boolean;
			originalReleaseYear?: number | null;
			upcomingReleases: Array<{
				__typename?: 'UpcomingRelease';
				releaseDate?: any | null;
				releaseCountDown?: number | null;
			}>;
		};
		show: {
			__typename: 'Show';
			id: string;
			objectId: number;
			objectType: Types.ObjectType;
			watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			content: { __typename?: 'ShowContent'; title: string };
		};
		fallBackClips: {
			__typename?: 'SeasonContent';
			clips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
			videobusterClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
			dailymotionClips: Array<{
				__typename?: 'Clip';
				sourceUrl: string;
				externalId: string;
				provider: Types.ClipProvider;
				name: string;
			}>;
		};
	}>;
	recentEpisodes: Array<{
		__typename?: 'Episode';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		uniqueOfferCount: number;
		seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		flatrate: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		buy: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		rent: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		free: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		fast: Array<{
			__typename?: 'Offer';
			id: string;
			package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
		}>;
		content: {
			__typename?: 'EpisodeContent';
			title: string;
			shortDescription?: string | null;
			episodeNumber: number;
			seasonNumber: number;
			isReleased: boolean;
			runtime?: number | null;
			upcomingReleases: Array<{
				__typename?: 'UpcomingRelease';
				releaseDate?: any | null;
				label: Types.UpcomingReleaseLabel;
				package?: { __typename?: 'Package'; id: string; packageId: number } | null;
			}>;
		};
	}>;
	plexPlayerOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	justwatchTVOffers: Array<{
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	}>;
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{
		__typename?: 'AvailableTo';
		availableCountDown: number;
		availableToDate: any;
		package: { __typename?: 'Package'; id: string; shortName: string };
	}>;
	fallBackClips: {
		__typename?: 'ShowContent';
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
	};
	content: {
		__typename?: 'ShowContent';
		originalTitle: string;
		ageCertification?: string | null;
		productionCountries: Array<any>;
		fullPath: string;
		posterUrl?: string | null;
		runtime?: number | null;
		isReleased: boolean;
		shortDescription?: string | null;
		title: string;
		originalReleaseYear?: number | null;
		originalReleaseDate?: any | null;
		fullPosterUrl?: string | null;
		subgenres: Array<{
			__typename?: 'GenericTitleList';
			content: {
				__typename?: 'GenericTitleListContent';
				shortName?: string | null;
				name: string;
				url?: { __typename?: 'Url'; fullPath: string } | null;
			};
		}>;
		credits: Array<{
			__typename?: 'Credit';
			role: Types.CreditRole;
			name: string;
			characterName?: string | null;
			personId: number;
		}>;
		interactions: {
			__typename?: 'InteractionAttributes';
			dislikelistAdditions: number;
			likelistAdditions: number;
			votesNumber: number;
		};
		tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
			name: string;
		}>;
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null; wikidataId?: string | null };
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseCountDown?: number | null;
			releaseDate?: any | null;
			releaseType: Types.ReleaseType;
			label: Types.UpcomingReleaseLabel;
			package?: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				shortName: string;
				clearName: string;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				iconWide: string;
				hasRectangularIcon: boolean;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					retailPrice?: string | null;
					durationDays?: number | null;
					presentationType?: Types.PresentationType | null;
					isTrial?: boolean | null;
					retailPriceValue?: number | null;
					currency?: string | null;
				}>;
			} | null;
		}>;
		genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
		textRecommendations?: Array<{
			__typename: 'TextRecommendation';
			id: string;
			headline?: string | null;
			body: string;
			originalHeadline?: string | null;
			originalBody?: string | null;
			customProfileType?: string | null;
			watchedAt: any;
			likeCount: number;
			likedByUser: boolean;
			ownedByUser: boolean;
			updatedAt: any;
			tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
			watchedOn?: {
				__typename?: 'Package';
				clearName: string;
				id: string;
				shortName: string;
				technicalName: string;
				packageId: number;
				selected: boolean;
				monetizationTypes: Array<Types.MonetizationType>;
				icon: string;
				addonParent?: { __typename?: 'Package'; id: string } | null;
			} | null;
			profile: {
				__typename: 'Profile';
				displayName: string;
				firstName: string;
				lastName: string;
				location?: string | null;
				country: any;
				bio?: string | null;
				avatarUrl?: string | null;
				isComplete: boolean;
				ownedByUser: boolean;
				profileUrl: string;
				profileType: Types.ProfileType;
				id: string;
				externalUrls?: Array<{
					__typename?: 'ProfileSpecificLink';
					type: Types.ProfileSpecificLinkType;
					name?: string | null;
					url: string;
				}> | null;
			};
			title?:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'SeasonContent';
							seasonNumber: number;
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						show: {
							__typename: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: { __typename?: 'ShowContent'; title: string; fullPath: string };
							watchNowOffer?: {
								__typename: 'Offer';
								id: string;
								standardWebURL?: string | null;
								preAffiliatedStandardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								dateCreated?: any | null;
								newElementCount?: number | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							} | null;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; progress: number };
							tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							upcomingReleases: Array<{
								__typename?: 'UpcomingRelease';
								releaseDate?: any | null;
								releaseCountDown?: number | null;
							}>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								jwRating?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
							};
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						availableTo: Array<{
							__typename?: 'AvailableTo';
							availableToDate: any;
							availableCountDown: number;
						}>;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  };
		}> | null;
	};
	popularityRank: {
		__typename?: 'PopularityRank';
		rank: number;
		trend: Types.ModuleContentTitleTrend;
		trendDifference: number;
	};
	streamingCharts: {
		__typename?: 'StreamingChartsConnection';
		edges?: Array<{
			__typename?: 'StreamingChartsTitlesEdge';
			streamingChartInfo?: {
				__typename?: 'StreamingChartInfo';
				rank: number;
				trend: Types.ModuleContentTitleTrend;
				trendDifference: number;
				updatedAt?: any | null;
				daysInTop10: number;
				daysInTop100: number;
				daysInTop1000: number;
				daysInTop3: number;
				topRank: number;
			} | null;
		}> | null;
	};
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleDetailsFragment =
	| TitleDetails_Movie_Fragment
	| TitleDetails_Season_Fragment
	| TitleDetails_Show_Fragment;

export const TitleDetailsFragmentDoc = gql`
	fragment TitleDetails on MovieOrShowOrSeason {
		id
		objectType
		objectId
		__typename
		plexPlayerOffers: offers(
			country: $country
			platform: $platform
			filter: { packages: ["pxp"], preAffiliate: true }
		) {
			...WatchNowOffer
		}
		justwatchTVOffers: offers(
			country: $country
			platform: $platform
			filter: { packages: ["jwt"], preAffiliate: true }
		) {
			...WatchNowOffer
		}
		disneyOffersCount: offerCount(country: $country, platform: $platform, filter: { packages: ["dnp"] })
		starOffersCount: offerCount(country: $country, platform: $platform, filter: { packages: ["srp"] })
		uniqueOfferCount: offerCount(country: $country, platform: $platform, filter: { bestOnly: true })
		offers(country: $country, platform: $platform, filter: { preAffiliate: true }) {
			...TitleOffer
		}
		watchNowOffer(country: $country, platform: $platform) {
			...WatchNowOffer
		}
		availableTo(country: $country, platform: $platform) {
			availableCountDown(country: $country)
			availableToDate
			package {
				id
				shortName
			}
		}
		fallBackClips: content(country: $country, language: "en") {
			clips {
				...TrailerClips
			}
			videobusterClips: clips(providers: [VIDEOBUSTER]) {
				...TrailerClips
			}
			dailymotionClips: clips(providers: [DAILYMOTION]) {
				...TrailerClips
			}
		}
		content(country: $country, language: $language) {
			backdrops {
				backdropUrl
			}
			fullBackdrops: backdrops(profile: S1920, format: JPG) {
				backdropUrl
			}
			clips {
				...TrailerClips
			}
			videobusterClips: clips(providers: [VIDEOBUSTER]) {
				...TrailerClips
			}
			dailymotionClips: clips(providers: [DAILYMOTION]) {
				...TrailerClips
			}
			externalIds {
				imdbId
				wikidataId
			}
			fullPath
			posterUrl
			fullPosterUrl: posterUrl(profile: S718, format: JPG)
			runtime
			isReleased
			scoring {
				imdbScore
				imdbVotes
				tmdbPopularity
				tmdbScore
				jwRating
				tomatoMeter
				certifiedFresh
			}
			shortDescription
			title
			originalReleaseYear
			originalReleaseDate
			upcomingReleases {
				releaseCountDown(country: $country)
				releaseDate
				releaseType
				label
				package {
					id
					packageId
					shortName
					clearName
					monetizationTypes
					icon(profile: S100)
					iconWide(profile: S160)
					hasRectangularIcon(country: $country, platform: WEB)
					planOffers(country: $country, platform: $platform) {
						retailPrice(language: $language)
						durationDays
						presentationType
						isTrial
						retailPriceValue
						currency
					}
				}
			}
			genres {
				shortName
				translation(language: $language)
			}
			subgenres {
				content(country: $country, language: $language) {
					shortName
					name
				}
			}
			textRecommendations(first: $first) {
				...TextRecommendation
			}
			... on MovieOrShowOrSeasonContent {
				subgenres {
					content(country: $country, language: $language) {
						url: moviesUrl {
							fullPath
						}
					}
				}
			}
			... on MovieOrShowContent {
				originalTitle
				ageCertification
				credits {
					role
					name
					characterName
					personId
				}
				interactions {
					dislikelistAdditions
					likelistAdditions
					votesNumber
				}
				productionCountries
			}
			... on MovieContent {
				tags {
					technicalName
					translatedName
				}
			}
			... on ShowContent {
				tags {
					technicalName
					translatedName
				}
			}
			... on SeasonContent {
				seasonNumber
				interactions {
					dislikelistAdditions
					likelistAdditions
					votesNumber
				}
				tags {
					technicalName
					translatedName
				}
			}
		}
		recommendedByCount
		watchedByCount
		popularityRank(country: $country) {
			rank
			trend
			trendDifference
		}
		streamingCharts(country: $country, filter: $streamingChartsFilter) {
			edges {
				streamingChartInfo {
					rank
					trend
					trendDifference
					updatedAt
					daysInTop10
					daysInTop100
					daysInTop1000
					daysInTop3
					topRank
				}
			}
		}
		likelistEntry {
			createdAt
		}
		dislikelistEntry {
			createdAt
		}
		... on MovieOrShow {
			watchlistEntryV2 {
				createdAt
			}
			customlistEntries {
				createdAt
				genericTitleList {
					id
				}
			}
			similarTitlesV2(country: $country, allowSponsoredRecommendations: $allowSponsoredRecommendations) {
				sponsoredAd {
					...SponsoredAd
				}
			}
		}
		... on Movie {
			permanentAudiences
			seenlistEntry {
				createdAt
			}
		}
		... on Show {
			permanentAudiences
			totalSeasonCount
			seenState(country: $country) {
				progress
				seenEpisodeCount
			}
			tvShowTrackingEntry {
				createdAt
			}
			offers(country: $country, platform: $platform, filter: { preAffiliate: true }) {
				...TitleOffer
			}
			seasons(sortDirection: DESC) {
				id
				objectId
				objectType
				totalEpisodeCount
				availableTo(country: $country, platform: $platform) {
					availableToDate
					availableCountDown(country: $country)
					package {
						id
						shortName
					}
				}
				offers(
					country: $country
					platform: $platform
					filter: { monetizationTypes: [BUY, RENT], preAffiliate: true }
				) {
					package {
						clearName
						shortName
					}
					monetizationType
					retailPrice(language: $language)
					retailPriceValue
				}
				content(country: $country, language: $language) {
					posterUrl
					seasonNumber
					fullPath
					title
					upcomingReleases {
						releaseDate
						releaseCountDown(country: $country)
					}
					isReleased
					originalReleaseYear
				}
				show {
					__typename
					id
					objectId
					objectType
					watchlistEntryV2 {
						createdAt
					}
					content(country: $country, language: $language) {
						title
					}
				}
				fallBackClips: content(country: $country, language: "en") {
					clips {
						...TrailerClips
					}
					videobusterClips: clips(providers: [VIDEOBUSTER]) {
						...TrailerClips
					}
					dailymotionClips: clips(providers: [DAILYMOTION]) {
						...TrailerClips
					}
				}
			}
			recentEpisodes: episodes(sortDirection: DESC, limit: 3, releasedInCountry: $country) {
				...Episode
			}
		}
		... on Season {
			totalEpisodeCount
			episodes(limit: $episodeMaxLimit) {
				...Episode
			}
			show {
				__typename
				id
				objectId
				objectType
				totalSeasonCount
				customlistEntries {
					createdAt
					genericTitleList {
						id
					}
				}
				tvShowTrackingEntry {
					createdAt
				}
				fallBackClips: content(country: $country, language: "en") {
					clips {
						...TrailerClips
					}
					videobusterClips: clips(providers: [VIDEOBUSTER]) {
						...TrailerClips
					}
					dailymotionClips: clips(providers: [DAILYMOTION]) {
						...TrailerClips
					}
				}
				content(country: $country, language: $language) {
					title
					ageCertification
					fullPath
					genres {
						shortName
					}
					credits {
						role
						name
						characterName
						personId
					}
					productionCountries
					externalIds {
						imdbId
					}
					upcomingReleases {
						releaseDate
						releaseType
						package {
							id
							shortName
							planOffers(country: $country, platform: $platform) {
								retailPrice(language: $language)
								durationDays
								presentationType
								isTrial
								retailPriceValue
								currency
							}
						}
					}
					backdrops {
						backdropUrl
					}
					posterUrl
					isReleased
					videobusterClips: clips(providers: [VIDEOBUSTER]) {
						...TrailerClips
					}
					dailymotionClips: clips(providers: [DAILYMOTION]) {
						...TrailerClips
					}
				}
				seenState(country: $country) {
					progress
				}
				watchlistEntryV2 {
					createdAt
				}
				dislikelistEntry {
					createdAt
				}
				likelistEntry {
					createdAt
				}
				similarTitlesV2(country: $country, allowSponsoredRecommendations: $allowSponsoredRecommendations) {
					sponsoredAd {
						...SponsoredAd
					}
				}
			}
			seenState(country: $country) {
				progress
			}
		}
	}
	${WatchNowOfferFragmentDoc}
	${TitleOfferFragmentDoc}
	${TrailerClipsFragmentDoc}
	${TextRecommendationFragmentDoc}
	${SponsoredAdFragmentDoc}
	${EpisodeFragmentDoc}
`;
