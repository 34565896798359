import { reactive } from 'vue';

import { useMutation } from '@/helpers/composables/useApollo';
import {
	UpdateProfileDocument,
	UpdateProfileMutation,
	UpdateProfileMutationVariables,
} from '@/features/user-profile/graphql/mutations/UpdateProfile.mutation';
import { getVm } from '@/helpers/vm-helper';
import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';

export function useUserProfileEdit() {
	const { country } = useLanguageStore();
	const { profileInfo } = useUserStore();

	const { mutate, loading, onError, onDone } = useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
		UpdateProfileDocument,
		{
			refetchQueries: ['GetUserStats', 'GetProfileByUrl'],
		}
	);

	const vm = getVm();

	const profileData = reactive({
		firstName: profileInfo.value?.firstName,
		lastName: profileInfo.value?.lastName,
		country: !profileInfo.value?.country ? country.value : profileInfo.value?.country,
		city: profileInfo.value?.city,
		bio: profileInfo.value?.bio,
		avatarBase64: null as string | null,
		website: profileInfo.value?.website?.url,
		altWebsite: profileInfo.value?.altWebsite?.url,
		instagram: profileInfo.value?.instagram?.url,
		imdb: profileInfo.value?.imdb?.url,
		x: profileInfo.value?.x?.url,
		tiktok: profileInfo.value?.tiktok?.url,
		linkedin: profileInfo.value?.linkedin?.url,
		facebook: profileInfo.value?.facebook?.url,
	});

	const countries = [
		{ item: vm.$tc('WEBAPP_COUNTRY_FR'), value: 'FR' },
		{ item: vm.$tc('WEBAPP_COUNTRY_DE'), value: 'DE' },
		{ item: vm.$tc('WEBAPP_COUNTRY_IT'), value: 'IT' },
		{ item: vm.$tc('WEBAPP_COUNTRY_PT'), value: 'PT' },
		{ item: vm.$tc('WEBAPP_COUNTRY_ES'), value: 'ES' },
		{ item: vm.$tc('WEBAPP_COUNTRY_TR'), value: 'TR' },
		{ item: vm.$tc('WEBAPP_COUNTRY_GB'), value: 'GB' },
		{ item: vm.$tc('WEBAPP_COUNTRY_US'), value: 'US' },
	];

	function submitProfile() {
		const externalLinksArray = [
			profileData.instagram,
			profileData.imdb,
			profileData.x,
			profileData.tiktok,
			profileData.linkedin,
			profileData.facebook,
		].filter(link => !!link);
		const websitesArray = [profileData.website, profileData.altWebsite].filter(link => !!link);

		mutate({
			input: {
				firstName: profileData.firstName,
				lastName: profileData.lastName,
				avatarBase64: profileData.avatarBase64,
				country: profileData.country,
				location: profileData.city,
				bio: profileData.bio,
				externalUrls: [...websitesArray, ...externalLinksArray],
			},
		});
	}

	return {
		profileData,
		submitProfile,
		countries,
		loading,
		onError,
		onDone,
	};
}
