import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type UpdateProfileMutationVariables = Types.Exact<{
	input: Types.UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
	__typename?: 'Mutation';
	updateProfile: {
		__typename?: 'UpdateProfilePayload';
		profile?: {
			__typename?: 'Profile';
			uuid: string;
			bio?: string | null;
			avatarUrl?: string | null;
			firstName: string;
			lastName: string;
			location?: string | null;
			isComplete: boolean;
			externalUrls?: Array<{
				__typename?: 'ProfileSpecificLink';
				name?: string | null;
				type: Types.ProfileSpecificLinkType;
				url: string;
			}> | null;
		} | null;
	};
};

export const UpdateProfileDocument = gql`
	mutation UpdateProfile($input: UpdateProfileInput!) {
		updateProfile(input: $input) {
			profile {
				uuid
				bio
				avatarUrl
				firstName
				lastName
				externalUrls {
					name
					type
					url
				}
				location
				isComplete
			}
		}
	}
`;
